/**
 * 项目动态配置，根据项目实际情况自行调整
 * @creater liux
 * @Email   liuxing@autobio.com.cn
 * @Date    2023年2月17日 16:00:00
 */

import qs from 'qs'
import Vue from 'vue'
import MD5 from '@/utils/md5'
// global state
import { initGlobalState } from 'qiankun'

/**********************用户信息、token等等信息*************************/
const LoginInfoHandle = {
  get() {
    let userInfo = sessionStorage.getItem(`${Vue.prototype.__name}-userInfo`) || '{}'
    try {
      userInfo = JSON.parse(userInfo)
    } catch (error) {
      console.error(error)
      userInfo = {}
    }
    return userInfo;
  },
  set(data) {
    if(JSON.stringify(data) != JSON.stringify(globalStateHandle.state.loginUserInfo)){
      sessionStorage.setItem(`${Vue.prototype.__name}-userInfo`,JSON.stringify(data))
    }
  },
  clear() {
    sessionStorage.removeItem(`${Vue.prototype.__name}-userInfo`);
  },
  // 是否已经登录或者登录是否过期
  isLogin() {
    var userInfo = this.get()
    return Boolean(userInfo.token)
  },
  // 获取调用接口需要必传的参数
  getCommonAjaxParam(userInfo) {
    userInfo = userInfo || this.get()
    return { Authorization: userInfo.token }
  },
  // 传递给第三方系统url的必要参数
  getThirdSysParams() {
    var userInfo = this.get()
    var thirdSysParams = {}
    var third_sys_param_keys = Vue.prototype.$config.thirdSysParams || {}
    Object.keys(third_sys_param_keys).forEach(k => { thirdSysParams[k] = userInfo[third_sys_param_keys[k]] || '' })
    return qs.stringify(thirdSysParams)
  },
  // 传递给自定义报表的url必要参数
  getAutoReportUrl(path) {
    var userInfo = this.get()
    var thirdSysParams = {}
    var third_sys_param_keys = Vue.prototype.$config.auto_report_params || Vue.prototype.$config.thirdSysParams || {}
    Object.keys(third_sys_param_keys).forEach(k => { thirdSysParams[k] = userInfo[third_sys_param_keys[k]] || '' })
    let url = path.replace('/autoReport/','')
    thirdSysParams['To'] = url.split('/')[0]
    thirdSysParams['UrlParams'] = url.split('/')[1]
    let qs = Object.keys(thirdSysParams).map(key=>{
      return encodeURIComponent(key) + '=' + encodeURIComponent(thirdSysParams[key])
    }).join('&')
    let encryption = ''
    Object.keys(thirdSysParams).sort().forEach(k=>{
      encryption += (thirdSysParams[k] || 'undefined')
    })
    qs += ('&Encrypted_Field=' + MD5(encryption))
    return Vue.prototype.$config.baseURL.autoReportURL + '?' + qs
  }
}

/***************************菜单相关*****************************/
const menuInfoHandle = {
  get() {
    return JSON.parse(sessionStorage.getItem(`${Vue.prototype.__name}-menus`) || '[]')
  },
  set(menus) {
    sessionStorage.setItem(`${Vue.prototype.__name}-menus`, JSON.stringify(menus || []));
  },
  getMenuRoutes() {
    return JSON.parse(sessionStorage.getItem(`${Vue.prototype.__name}-dynamicMenuRoutes`) || '[]')
  },
  setMenuRoutes(routes) {
    sessionStorage.setItem(`${Vue.prototype.__name}-dynamicMenuRoutes`, JSON.stringify(routes || []));
  },
  getCurSystemInfo(curApp){
    let systems = LoginInfoHandle.get().systemList || []
    let system = systems.find(s=>s.systemCode == curApp)
    return system || {}
  },
  // 获取当前系统home path
  getCurAppHome(curApp,appName){
    let systems = LoginInfoHandle.get().systemList || []
    let system = systems.find(s=>s.systemCode == curApp)
    if(system){
      return `/${appName || system.appName}/${system.systemCode}/home`.replace(/\/\//g,'/')
    }else{
      return '/home'
    }
  },
  // 调用接口获取
  getMenus() {
    // 异步获取服务器返回的菜单
    return new Promise((resolve, reject) => {
      // 是否已登录
      if (LoginInfoHandle.isLogin()) {
        let systemCode = localStorage.getItem(Vue.prototype.$config.defApp)
        let baseURL = (Vue.prototype.$config.apps.find(r=>r.systemCode == systemCode) || {}).baseURL 
        || Vue.prototype.$config.baseURL.default
        // 获取接口数据
        Vue.prototype.$ajax.post(Vue.prototype.$api.common.getMenu,{
          systemCode: localStorage.getItem(Vue.prototype.$config.defApp)
        },{ baseURL: baseURL }).then(res => {
          // 组装菜单数据  菜单数据有固定格式要求，如下：
          // var menus = [{
          //   // 菜单唯一值
          //   id: '1',
          //   // 菜单显示名称
          //   label: '主菜单',
          //   // 菜单图标 -- icon class
          //   icon: 'ion-main-menu',
          //   // 菜单路径
          //   url: '/main',
          //   // 菜单类型  1: 默认，2: iframe，3：另打开浏览器页签
          //   type: '1',
          //   该菜单是否需要二次登录
          //   isLogin: true,
          //   该菜单是否需要默认打开
          //   isOpen: true,
          //   /****第三方应用****/
          //   // 应用名称，匹配页面元素及应用地址，具体信息请关注配置文件
          //   appName: 'sys',
          //   // children 格式同上， children内有值以上路径及三方应用跳转逻辑不执行
          //   children: []
          // }]
          // 菜单列表
          let menus = res.data || []
          // _id 内部使用ID
          for (let index = 0; index < menus.length; index++) {
            const menu = menus[index];
            menu._id = (menu.appName || '') + '_' + menu.id
            if(menu.type == 1 && !/^http[s]?:\/\/.*/.test(menu.url)){
              menu.url = `${menu.appName ? '/' + menu.appName : ''}${menu.url}`
            }
            if(menu.children && menu.children.length){
              for (let j = 0; j < menu.children.length; j++) {
                const c_m = menu.children[j];
                c_m._parentId = menu._id
                c_m._id = (c_m.appName || '') + '_' + c_m.id
                if(c_m.type == 1 && !/^http[s]?:\/\/.*/.test(c_m.url)){
                  c_m.url = `${c_m.appName ? '/' + c_m.appName : ''}${c_m.url}`
                }
              }
            }
          }
          // 
          resolve(menus)
        }).catch(err => {
          reject('获取菜单接口调用失败：' + err.message)
        })
      } else {
        reject('用户未登录！')
      }
    })
  }
}

/***************************GlobalState*****************************/
// 默认仅仅传递token等用户信息
const globalStateHandle = {
  // state
  state: {},
  // action
  action: null,
  // 回调缓存数组
  changeHandlers: [],
  // 创建句柄
  initGlobalState(action,initGlobalStateData) {
    this.action = action ? action : this.action
    if (!this.action) {
      this.state = this.initGlobalStateData()
      this.action = initGlobalState(this.state)
    }else{
      this.state = Object.assign({},initGlobalStateData,this.state)
    }
    this.action.onGlobalStateChange((state) => {
      this.state = Object.assign({}, this.state, state)
      if (state.loginUserInfo.Token != Vue.prototype.$userInfo.getLoginInfo().access_token) { 
        Vue.prototype.$userInfo.transferUserDataForRemote(state.loginUserInfo,false)
      }
      this.changeHandlers.forEach(func => { func && typeof (func) == 'function' && func(this.state) })
    })
  },
  // 监听变化
  onGlobalStateChange(func) {
    func && this.changeHandlers.push(func)
  },
  // 删除监听
  removeGlobalStateChange(func) {
    let i = this.changeHandlers.indexOf(func)
    i !== -1 && this.changeHandlers.splice(i, 1)
  },
  // 更新消息
  setGlobalState(info) {
    if (this.action) {
      this.state = Object.assign({}, this.state, info)
      this.action.setGlobalState(this.state)
    }
  },
  // initGlobalStateData
  initGlobalStateData(all_menus) {
    let sys_state = {}
    Object.keys(Vue.prototype.$config.qiankunAppEntrys || {}).forEach(key=>{
      sys_state[key] = {
        navigatorBtns: Vue.prototype.$config.navigatorBtns
      }
    })
    return Object.assign({},{ loginUserInfo: LoginInfoHandle.get() },{all_menus: all_menus},{ sys_state })
  }
}

export {
  LoginInfoHandle, menuInfoHandle, globalStateHandle
}